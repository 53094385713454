import {
    AcademicCapIcon,
    CubeTransparentIcon,
    FireIcon,
} from "@heroicons/react/24/outline";
import BasicSection from "components/BasicSection";
import Layout from "components/Layout";
import { graphql, Link, PageProps } from "gatsby";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import sal from "sal.js";

interface DataProps {
    allMdx: {
        nodes: DataPropsNode[];
    };
}

interface DataPropsNode {
    frontmatter: {
        title: string;
        description: string;
        date: string;
        categories: string[];
    };
    fields: {
        slug: string;
        sourceInstanceName: string;
    };
    id: string;
    excerpt: string;
}

const Blog: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const {
        allMdx: { nodes },
    } = data;

    const [activeCategory, setActiveCategory] = useState<string>("All");
    const categories = ["All", "Product", "Company", "Tech", "Articles", "Random"];

    /**
     * "sal" is coming via "gatsby-plugin-scroll-reveal"
     * Blog Cards present lower on the list remained hidden after filtering due to
     * initial viewport rendering. sal() triggers a refresh, displaying cards that have
     * moved to the top and are now in the viewport.
     */
    useEffect(() => {
        sal();
    }, [activeCategory]);

    const handleCategoryChange = (category: string) => {
        setActiveCategory(category);
    };

    const filteredPosts =
        activeCategory === "All"
            ? nodes
            : nodes.filter(
                  (node) =>
                      node.frontmatter.categories &&
                      node.frontmatter.categories.includes(activeCategory),
              );

    return (
        <Layout title="Ente - Blog" path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>Blog</BasicSection.Heading>
                </BasicSection.Header>

                <BasicSection.Body>
                    <CategoryButtonWrapper>
                        {categories.map((category) => (
                            <CategoryButton
                                key={category}
                                $active={category === activeCategory}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </CategoryButton>
                        ))}
                    </CategoryButtonWrapper>

                    <BlogPostList nodes={filteredPosts} />
                </BasicSection.Body>
            </BasicSection>
        </Layout>
    );
};

export default Blog;

const CategoryButtonWrapper = styled.nav`
    display: flex;
    justify-content: center;
    margin-bottom: 42px;
    overflow-x: scroll;
    white-space: pre;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 626px) {
        justify-content: flex-start;
    }
`;

interface CategoryButtonProps {
    $active: boolean;
}

const CategoryButton = styled.div<CategoryButtonProps>`
    display: inline-block;
    padding: 4px 24px;
    color: ${({ $active }) =>
        $active ? "var(--color-primary-500)" : "var(--color-muted)"};
    font-size: 16px;
    &:hover {
        cursor: pointer;
    }
    background: var(--color-elevated-bg-hover);
    border-radius: 6px;
    margin-right: 8px;
`;

export const query = graphql`
    query {
        allMdx(
            filter: { fields: { sourceInstanceName: { eq: "blog" } } }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            nodes {
                frontmatter {
                    title
                    description
                    date(formatString: "MMMM D, YYYY")
                    categories
                }
                fields {
                    slug
                    sourceInstanceName
                }
                id
                excerpt(pruneLength: 90)
            }
        }
    }
`;

const BlogPostList: React.FC<{ nodes: DataPropsNode[] }> = ({ nodes }) => {
    return (
        <div role="list" className="d-flex flex-column gap-4 mx-md-5">
            {nodes.map((node) => (
                <BlogPostCard key={node.id} {...{ node }} />
            ))}
        </div>
    );
};

interface BlogPostCardProps {
    node: DataPropsNode;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ node }) => {
    const {
        frontmatter: { title, description, date },
        fields: { slug, sourceInstanceName },
        excerpt,
    } = node;

    const path = `/${sourceInstanceName}${slug}`;
    return (
        <div role="listitem" data-sal="slide-up" data-sal-duration="600">
            <BlogPostLink to={path} state={{ fromBlogIndex: true }}>
                <div className="card bg-elevated position-relative blog-post-card">
                    <CardContents className="card-body mx-3 my-2 my-md-3">
                        <h5 className="card-title mt-1">{title}</h5>
                        <div className="card-subtitle text-muted">
                            <small>{date}</small>
                        </div>
                        <p className="card-text text-muted mt-md-3">
                            {description || excerpt}
                        </p>
                    </CardContents>
                    <CardIcon {...{ slug }} />
                </div>
            </BlogPostLink>
        </div>
    );
};

const BlogPostLink = styled(Link)`
    text-decoration: none;

    &&:hover .card {
        background-color: var(--color-elevated-bg-hover);
        border-color: var(--bs-border-color);
    }
`;

const CardContents = styled.div`
    z-index: 1;
`;

const CardIconContainer = styled.div`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    opacity: 17%;

    width: 36px;
    height: 36px;

    /* On the Bootstrap sm breakpoint, increase the icon size */
    @media (min-width: 576px) {
        width: 48px;
        height: 48px;
    }
`;

const CardIcon: React.FC<{ slug: string }> = ({ slug }) => {
    const icon = getIcon(slug);

    return (
        <CardIconContainer className="d-flex text-muted">
            {icon}
        </CardIconContainer>
    );
};

/**
 * We currently don't have post categories. Instead, use the slug prefix (which
 * is the folder of the blog post) to determine the icon
 */
const getIcon = (slug: string) => {
    if (slug.startsWith("/tech/")) {
        return <CubeTransparentIcon />;
    }

    if (slug.startsWith("/r/")) {
        return <AcademicCapIcon />;
    }

    // Product updates live at root.
    return <FireIcon />;
};
